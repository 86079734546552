import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getCaseStudySladkaDilnaPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/portfolio/sladka-dilna",
		[Locale.en]: "/portfolio/sladka-dilna",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
