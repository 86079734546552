import imgHap from "@assets/images/havelpartners-project.png?h=445&webp&imagetools";
import imgJamujamMobile from "@assets/images/jamujam-player-project.png?h=445&webp&imagetools";
import imgJamujamDeskopAndMobile from "@assets/images/jamujam-website-and-player-project.png?h=493&webp&imagetools";
import l404 from "@assets/images/logos/404-logo.svg";
import alpergoly from "@assets/images/logos/alpergoly-logo.svg";
import aukro from "@assets/images/logos/aukro-logo.svg";
import b7 from "@assets/images/logos/b7-logo.svg";
import bito from "@assets/images/logos/bito-logo.svg";
import brano from "@assets/images/logos/brano-logo.svg";
import cihelni from "@assets/images/logos/cihelni-logo.svg";
import czu from "@assets/images/logos/czu-logo.svg";
import diplomkyaseminarky from "@assets/images/logos/diplomkyaseminarky-logo.svg";
import havelpartners from "@assets/images/logos/havelpartners-logo.svg";
import jamujam from "@assets/images/logos/jamujam-logo.svg";
import kamenynaburni from "@assets/images/logos/kamenynaburni-logo.svg";
import kvetinkatrinec from "@assets/images/logos/kvetinka-logo.svg";
import oxpoint from "@assets/images/logos/oxpoint-logo.svg";
import pospiech from "@assets/images/logos/pospiech-logo.svg";
import sineko from "@assets/images/logos/sineko-logo.svg";
import sladkadilna from "@assets/images/logos/sladkadilna-logo.svg";
import tymbe from "@assets/images/logos/tymbe-logo.svg";
import vertiflex from "@assets/images/logos/vertiflex-logo.svg";
import volvo from "@assets/images/logos/volvo-logo.svg";
import zms from "@assets/images/logos/zms-logo.svg";
import imgOx from "@assets/images/oxpoint-project.png?h=600&webp&imagetools";
import imgSladkaDilna from "@assets/images/references/sladkadilna.jpg?w=445&webp&imagetools";
import imgTymbe from "@assets/images/references/tymbe.jpg?h=445&webp&imagetools";
import imgVertiflexResponsive from "@assets/images/references/vertiflex-project-responsive.png?h=493&webp&imagetools";
import imgVertiflex from "@assets/images/references/vertiflex.jpg?h=445&webp&imagetools";
import imgSineko from "@assets/images/sineko-project.png?h=445&webp&imagetools";
import imgVolvo from "@assets/images/volvo-project.png?h=445&webp&imagetools";
import type { Tenant } from "@core/schema/Tenant.js";
import { getCaseStudySladkaDilnaPath } from "@core/schema/paths/getCaseStudySladkaDilnaPath.js";

// import imgBrano from "@assets/images/references/brano.png?w=445&webp&imagetools";
// import imgAlpergoly from "@assets/images/references/alpergoly.png?w=445&webp&imagetools";
// import imgHavelPartners from "@assets/images/references/havelpartners.jpg?w=445&webp&imagetools";
// import imgJamujam from "@assets/images/references/jamujam.png?w=445&webp&imagetools";
// import imgKamenyNaBurni from "@assets/images/references/kamenynaburni.jpg?w=445&webp&imagetools";
// import imgOxpoint from "@assets/images/references/oxpoint.png?w=445&webp&imagetools";
// import imgSladkaDilna from "@assets/images/references/sladkadilna.jpg?w=445&webp&imagetools";
// import imgTymbe from "@assets/images/references/tymbe.jpg?w=445&webp&imagetools";
// import imgVertiflex from "@assets/images/references/vertiflex.png?w=445&webp&imagetools";
// import imgVolvo from "@assets/images/references/volvo.png?w=445&webp&imagetools";

export enum ReferencedClients {
	Bito = "Bito",
	Zms = "Zms",
	Kvetinka = "Kvetinka",
	KamenyNaBurni = "KamenyNaBurni",
	Diplomky = "Diplomky",
	Czu = "Czu",
	Brano = "Brano",
	SladkaDilna = "SladkaDilna",
	Tymbe = "Tymbe",
	OxPoint = "OxPoint",
	AlPergoly = "AlPergoly",
	Sineko = "Sineko",
	Horse = "Horse",
	Aukro = "Aukro",
	VertiFlex = "VertiFlex",
	HavelPartners = "HavelPartners",
	Volvo = "Volvo",
	Jamujam = "Jamujam",
	FourZeroFour = "FourZeroFour",
	B7 = "B7",
	Cihelni = "Cihelni",
	Pospiech = "Pospiech",
}

export interface LogoProps {
	src: string;
	width: number;
	height: number;
	alt?: string;
}

export interface ReferenceProps {
	name: string;
	image?: string;
	website?: string;
	logo?: LogoProps;
	desc?: string;
	descHire?: string;
	descWebsite?: string;
	descPortfolio?: string;
	descRescue?: string;
	descCloud?: string;
	descServices?: string;
	href?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
export function getReferences(tenant: Tenant) {
	return {
		[ReferencedClients.SladkaDilna]: {
			name: "Sladká dílna",
			website: "https://sladkadilna.cz",
			desc: "Cukrárna Sladká dílna od nás dostala na zakázku vyvinuté sp[[komplexní e-shopové řešení v cloudu AWS.]] Součástí byl také administrační systém na míru pro správu objednávek, produktů, výroben a obsahu webu. Naše řešení je propojeno s obrazovkami a tiskárnami přímo na výrobnách a zajišťuje tak efektivitu a plynulost výroby.",
			descWebsite:
				"Pro Cukrárnu Sladká dílna jsme na zakázku vyvinuli sp[[komplexní řešení v cloudu AWS]], včetně administračního systému pro správu objednávek, produktů, výroben a obsahu webu. Je propojeno s obrazovkami a tiskárnami ve výrobnách a zajišťuje efektivní a plynulou výrobu.",
			logo: {
				src: sladkadilna,
				width: 80,
				height: 80,
				alt: "Sladká dílna",
			},
			href: getCaseStudySladkaDilnaPath(tenant),
			image: imgSladkaDilna,
		},
		[ReferencedClients.OxPoint]: {
			name: "OX Point",
			website: "",
			logo: {
				src: oxpoint,
				width: 147,
				height: 36,
				alt: "OX Point",
			},
			image: imgOx,
			desc: "Síť zásilkových míst OX Point od nás dostala sp[[systém na doručování zboží do boxů,]] který má podobu plně škálovatelného bezserverového řešení v cloudu AWS. Obsahuje kioskovou aplikaci pro obsluhu schránek a platebních terminálů, stejně jako aplikaci pro správu sítě výdejních boxů. Součástí je integrace se světovými i lokálními dopravci.",
			descPortfolio:
				"Síť zásilkových míst OX Point od nás dostala sp[[systém na doručování zboží do boxů,]] který má podobu plně škálovatelného bezserverového řešení v cloudu AWS. Obsahuje kioskovou aplikaci pro obsluhu schránek a platebních terminálů, stejně jako aplikaci pro správu sítě výdejních boxů. Součástí je integrace se světovými i lokálními dopravci. Technologické základy zahrnují Amazon Web Services (AWS), Angular, Node.js, GraphQL, REST API, Electron, TypeScript, IaC nebo Jest.",
			descCloud:
				"Síť zásilkových míst OX Point od nás dostala sp[[systém na doručování zboží do boxů,]] který má podobu plně škálovatelného bezserverového řešení v cloudu AWS. Obsahuje kioskovou aplikaci pro obsluhu schránek a platebních terminálů, stejně jako aplikaci pro správu sítě výdejních boxů.",
		},
		[ReferencedClients.Tymbe]: {
			name: "Tymbe",
			website: "",
			logo: {
				src: tymbe,
				width: 130,
				height: 48,
				alt: "Tymbe",
			},
			image: imgTymbe,

			desc: "Tymbe je mobilní aplikace pro brigádníky. Ti se přes ni mohou přihlásit na brigády, které v aplikaci inzerují stovky firem. Naším úkolem bylo pro Tymbe vytvořit sp[[systém na nábor a dodání pracovních sil, docházkový systém]] s průběžnými výplatami odměn nebo sp[[legislativní knihovnu výpočtu mezd]] pro Českou republiku.",
			descRescue:
				"Tymbe nás oslovilo v moment, kdy potřebovali pomoct při komplexnější implementaci účetních výpočtů. V čase jsme škálovali náš tým od 1 vývojáře a 1 manažera až po pronájem týmu o 6 vývojářích a 2 analytiků. Doplnili jsme tak potřebné řady podle aktuální potřeby zákazníka a produktu. Náš tým se adaptoval velmi rychle a tak jsme byli schopni vypomoct i s dalším rozvojem systému, návrhem architektury a implementací základu systému.",
			descHire:
				"Tymbe je aplikace, ve které zájemci o brigády najdou příležitosti z celého Česka. Firma má svůj vývojový tým, kterému ale chyběly zkušenosti s komplikovanějším vývojem a časová kapacita na implementaci některých zásadních vylepšení. Proto jsme klientovi pronajali IT tým (v jednu chvíli šlo dokonce o 2 nezávislé týmy). Naši specialisté tak na projektu kooperovali společně s interními vývojáři Tymbe. S firmou spolupracujeme dodnes.",
		},
		[ReferencedClients.AlPergoly]: {
			name: "AL Pergoly",
			website: "",
			logo: {
				src: alpergoly,
				width: 237.78,
				height: 10,
				alt: "AL Pergoly",
			},
			desc: "Pro AL Pergoly s.r.o., českého výrobce hliníkových pergol na míru, jsme vytvořili sp[[produktový katalog v cloudu AWS spolu s poptávkovým systémem a administračním řešením na míru.]] K dispozici je také blogová platforma. Samozřejmostí jsou výkonnostní optimalizace a strukturovaná data. Uživatelé mohou snadno procházet nabídkou díky multilevel kategorickému menu.",
		},
		[ReferencedClients.Sineko]: {
			name: "Sineko Engineering",
			website: "",
			logo: {
				src: sineko,
				width: 131.25,
				height: 35,
				alt: "Sineko Engineering",
			},
			image: imgSineko,

			desc: "Zakázkový vývoj sp[[komplexního e-shopového řešení v cloudu AWS spolu s administračním systémem na míru.]] Platforma poskytuje českou i slovenskou verzi e-shopu, což umožňuje lepší zacílení na daný trh. Dále podporuje online splátkový prodej a generuje produktové feedy pro Google, Zboží.cz a Heureku. Pro rychlé fulltextové hledání využíváme technologii Algolia.",
		},
		[ReferencedClients.Horse]: {
			name: "Horse",
			website: "",
			logo: {
				src: l404,
				width: 90,
				height: 36,
				alt: "Horse",
			},
			desc: "Aplikaci Horse se rozpadl vývojový tým propůjčený od jiného dodavatele. Do rukou jsme tak dostali nedokončený projekt. Podařilo se dotáhnout klíčové funkcionality a brzy hotový projekt vypustit do světa. Na záchraně pracoval 1 projektový manažer a 1 vývojář.",
		},
		[ReferencedClients.Aukro]: {
			name: "Aukro",
			website: "",
			logo: {
				src: aukro,
				width: 106.15,
				height: 30,
				alt: "Aukro",
			},
			descHire:
				"IT oddělení tohoto aukčního portálu procházelo obměnou, a tak v dané chvíli nemělo na některé záležitosti dostatek kapacit a dovedností. Náš tým Aukru pomohl optimalizovat rychlost systému při rebrandingu firmy.",
		},
		[ReferencedClients.VertiFlex]: {
			name: "VertiFlex",
			website: "",
			logo: {
				src: vertiflex,
				width: 182.09,
				height: 20,
				alt: "VertiFlex",
			},
			image: imgVertiflexResponsive,
			descServices:
				"Společnost Vertiflex potřebovala co nejdřív spustit nový web. Jenže ani po 6 měsících nebylo skoro nic připraveno, vedení firmy navíc nebylo spokojeno s grafikou webu od externího dodavatele. A tak jsme přiložili ruku k dílu. Náš produktový manažer Martin v projektu nastolil systém a díky úsilí dalších členů týmu se web povedlo úspěšně spustit do 2 měsíců. Na zakázce se podílel 1 projektový manažer a 2 vývojáři.",
			descPortfolio:
				"Pro firmu s českými kořeny VertiFlex s.r.o., která má našlápnuto do celého skladového světa, jsme vyvinuli sp[[webové stránky na míru.]] Výsledkem je moderní vizitka, za kterou se nemusí stydět před svými klienty, partnery ani před konkurencí. Díky správně navržené datové vrstvě aplikace jsme VertiFlex v tomto ohledu připravili na expanzi. Začali jsme jen s anglickou a českou mutací, ale přidání dalších mutací je otázkou drobných rozšíření systému.",
			descWebsite:
				"Firma VertiFlex s.r.o., která má našlápnuto do celého skladového světa, sp[[od nás dostala webové stránky na míru.]] Výsledkem je moderní vizitka, za kterou se nemusí stydět před svými klienty, partnery ani před konkurencí. Díky správně navržené datové vrstvě aplikace jsme VertiFlex v tomto ohledu připravili na expanzi.",
		},
		[ReferencedClients.HavelPartners]: {
			name: "Havel & Partners",
			website: "https://www.havelpartners.blog",
			logo: {
				src: havelpartners,
				width: 166,
				height: 33,
				alt: "Havel & Partners",
			},
			image: imgHap,
			desc: "Česko-slovenské advokátní kanceláři HAVEL & PARTNERS jsme dodali blog na míru, a to na serverless architektuře cloudu AWS. Součástí je administrační systém navržený přesně podle klientových potřeb. Platforma podporuje českou i anglickou verzi blogu a díky naší pečlivé výkonnostní optimalizaci zajišťuje vysoké hodnocení v nástroji PageSpeed Insights. Řešení umožňuje i škálování obrázků pro další navýšení výkonu. Díky využití technologie Algolia lze na blogu používat rychlé fulltextové hledání.",
			descWebsite:
				"Česko-slovenské advokátní kanceláři HAVEL & PARTNERS jsme dodali sp[[blog na míru,]] a to na serverless architektuře cloudu AWS. Součástí je administrační systém navržený přesně podle klientových potřeb. Platforma podporuje českou i anglickou verzi blogu a díky naší pečlivé výkonnostní optimalizaci zajišťuje vysoké hodnocení v nástroji PageSpeed Insights.",
		},
		[ReferencedClients.Volvo]: {
			name: "Volvo",
			website: "",
			logo: {
				src: volvo,
				width: 80,
				height: 80,
				alt: "Volvo",
			},
			image: imgVolvo,

			desc: "Pro společnost Volvo Group Czech Republic, s.r.o. jsme ve spolupráci s AstrumQ, která zajistila obchodní komunikaci a UX/UI aplikace, vyvinuli sp[[kompletní interní pokladní systém.]] Za Fastest Solution jsme zajistili důležitý komunikační můstek s interními systémy Volva, technický návrh a vývoj webové aplikace na serverless architektuře cloudu AWS. I přes stanovenou korporátní infrastrukturu jsme integrovali moderní technologie. Podařilo se napojit platební terminál ČSOB a stali jsme se certifikovanými partnery ČSOB GAPA. Aplikace obsahuje nové funkcionality, které usnadňují každodenní práci zaměstnancům i vedoucím pracovníkům. Mezi využité AWS technologie patří DynamoDB, Lambda, AppSync a Cognito.",
		},
		[ReferencedClients.Jamujam]: {
			name: "Jamujam",
			website: "",
			logo: {
				src: jamujam,
				width: 130,
				height: 48,
				alt: "Jamujam",
			},

			image: imgJamujamDeskopAndMobile,
			desc: "Jamujam, sp[[sociální síť a nahrávací studio v cloudu AWS]], se zaměřuje na milovníky hudby a nabízí nástroje pro online spolupráci při skládání hudby. Uživatelé sítě mohou vytvářet hudbu buď samostatně, nebo ve spolupráci s ostatními členy sítě, a to v uzavřené skupině i veřejně. Použili jsme například technologie jako Amazon Web Services (AWS), Angular, GraphQL, TypeScript, IaC a Node.js.",
			descCloud:
				"Bavila nás také práce na JamuJam, sp[[sociální síti a nahrávacím studiu v cloudu AWS.]] Nabízí nástroje pro online spolupráci při skládání hudby. Při vývoji jsme použili Amazon Web Services (AWS), Angular nebo TypeScript, IaC, GraphQL a Node.js.",
		},
		[ReferencedClients.KamenyNaBurni]: {
			name: "Kameny Na Burni",
			logo: {
				src: kamenynaburni,
				width: 157,
				height: 40,
				alt: "Kameny Na Burni",
			},
		},
		[ReferencedClients.Kvetinka]: {
			name: "Květinka Třinec",
			logo: {
				src: kvetinkatrinec,
				width: 143,
				height: 48,
				alt: "Květinka Třinec",
			},
		},
		[ReferencedClients.Zms]: {
			name: "ZM Servis Moravia",
			logo: {
				src: zms,
				width: 90,
				height: 45,
				alt: "ZM Servis Moravia",
			},
		},
		[ReferencedClients.Diplomky]: {
			name: "Diplomky a Seminárky",
			logo: {
				src: diplomkyaseminarky,
				width: 154,
				height: 46,
				alt: "Diplomky a Seminárky",
			},
		},
		[ReferencedClients.Czu]: {
			name: "ČZU",
			logo: {
				src: czu,
				width: 91.25,
				height: 45,
				alt: "ČZU",
			},
		},
		[ReferencedClients.Brano]: {
			name: "Brano Group",
			logo: {
				src: brano,
				width: 88,
				height: 59,
				alt: "Brano Group",
			},
		},
		[ReferencedClients.Bito]: {
			name: "BITO – Skladovací technika",
			logo: {
				src: bito,
				width: 79,
				height: 38,
				alt: "BITO – Skladovací technika",
			},
		},
		[ReferencedClients.FourZeroFour]: {
			name: "404",
			logo: {
				src: l404,
				width: 90,
				height: 36,
				alt: "404",
			},
		},
		[ReferencedClients.B7]: {
			name: "Beskydská sedmička",
			logo: {
				src: b7,
				width: 121,
				height: 45,
				alt: "Beskydská sedmička",
			},
		},
		[ReferencedClients.Pospiech]: {
			name: "Pospiech – Special Delivery",
			logo: {
				src: pospiech,
				width: 123.47,
				height: 50,
				alt: "Pospiech – Special Delivery",
			},
		},
		[ReferencedClients.Cihelni]: {
			name: "ZŠ & MŠ Cihelní Karviná",
			logo: {
				src: cihelni,
				width: 180,
				height: 21.32,
				alt: "ZŠ & MŠ Cihelní Karviná",
			},
		},
	} satisfies Record<ReferencedClients, ReferenceProps>;
}
